
function Footer(props) {

    // const footerStyle = {
    //     position: "absolute",
    //     bottom: "0",
    //     width: "100%",
    //     height: "3rem"
    // }
    return (
    <div>   
        <h2>FullStack Software Engineer | Javascript | MongoDB | REACT</h2>
        <h2>CSS | HTML | JQuery | Github | Mom | Love to Cook</h2>
    </div>
    )
}

export default Footer;